import React, { lazy, Suspense } from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../../../components/shared-components/Loading';

import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../../constants/ThemeConstant';
import BorrowerHeaderNav from '../../../components/layout-components/BorrowerHeaderNav';
import ErrorAccessDenied from '../../errors-views/error-403';

const { Content } = Layout;

const Pages = (props) => {
  const isMobile = true;
  const isNavTop = props.navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (props.isAppLayoutHidden) {
      return 0;
    }
    if (isNavTop || isMobile) {
      return 0;
    }
    return props.navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const headerComponent = props.isAppLayoutHidden ? null
    : <BorrowerHeaderNav isMobile={isMobile} isLoggedIn />;

  if (props.auth.user && props.auth.user.type !== 'borrower') {
    return <ErrorAccessDenied />;
  }

  document.title = 'Home';

  return (
    <Layout
      style={{
        backgroundColor: 'white', maxWidth: '500px', margin: 'auto', height: '100%',
      }}
    >
      {headerComponent}
      <Layout className="app-container">
        <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
          <div className={`app-content overflow-scroll ${props.isAppLayoutHidden ? 'm-0' : ''}`}>
            <div className="container pt-2 mb-3">
              <Content>
                <Suspense fallback={<Loading cover="page" />}>
                  <Switch>
                    <Route path={`${props.match.url}/borrower-dashboard`} component={lazy(() => import('./borrower-dashboard'))} />
                    <Route path={`${props.match.url}/home`} component={lazy(() => import('./home'))} />
                    <Route path={`${props.match.url}/kyc-documents`} component={lazy(() => import('./kyc-documents'))} />
                    <Route path={`${props.match.url}/basic-details`} component={lazy(() => import('./basic-details'))} />
                    <Route path={`${props.match.url}/addresses`} component={lazy(() => import('./addresses'))} />
                    <Route path={`${props.match.url}/vehicle-details`} component={lazy(() => import('./vehicle-details'))} />
                    <Route path={`${props.match.url}/bank-details`} component={lazy(() => import('./bank-details'))} />
                    <Redirect to={`${props.match.url}/borrower-dashboard`} />
                  </Switch>
                </Suspense>
              </Content>
            </div>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

Pages.propTypes = {
  navCollapsed: PropTypes.bool.isRequired,
  isAppLayoutHidden: PropTypes.bool.isRequired,
  navType: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ theme, auth }) => {
  const {
    navCollapsed, navType, locale, isAppLayoutHidden,
  } = theme;
  return {
    navCollapsed, navType, locale, isAppLayoutHidden, auth,
  };
};

export default withRouter(connect(mapStateToProps, null)(React.memo(Pages)));
