import { CLOSE_USER_PREVIEW, OPEN_USER_PREVIEW } from '../action-types';

export const openUserPreview = (payload) => ({
  type: OPEN_USER_PREVIEW,
  payload,
});

export const closeUserPreview = (payload) => ({
  type: CLOSE_USER_PREVIEW,
  payload,
});
