import { CLOSE_LEAD_PREVIEW, OPEN_LEAD_PREVIEW } from '../action-types';

export const openLeadPreview = (payload) => ({
  type: OPEN_LEAD_PREVIEW,
  payload,
});

export const closeLeadPreview = (payload) => ({
  type: CLOSE_LEAD_PREVIEW,
  payload,
});
