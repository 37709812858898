import { env } from '../configs/EnvironmentConfig';

export const SESSION_ID_KEY = 'sid';
export const FCM_TOKEN = 'fcmt';
export const APP_NAME = 'Aris Capital';
export const SUPPORT_NUMBER = '+91-9953162944';
export const SUPPORT_EMAIL = 'support@ariscapital.in';
export const REPORT_ISSUE_LINK = '';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const HOME_PREFIX_PATH = '/v1';
export const AUTH_PREFIX_PATH = '/auth';
export const ERROR_PREFIX_PATH = '/error';
export const SESSION_HEADER_KEY = 'authorization';
