/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from '../../constants/ThemeConstant';
import { onMobileNavToggle, toggleCollapsedNav } from '../../redux/actions';
import Utils from '../../utils';
import NavHelp from './NavHelp';

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed, mobileNav, navType, headerNavColor, isMobile,
  } = props;

  // const [searchActive, setSearchActive] = useState(false);

  // const onSearchActive = () => {
  //   setSearchActive(true);
  // };

  // const onSearchClose = () => {
  //   setSearchActive(false);
  // };

  const onToggle = () => {
    if (!isMobile) {
      props.toggleCollapsedNav(!navCollapsed);
    } else {
      props.onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP;
  const mode = Utils.getColorContrast(headerNavColor);
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };
  return (
    <Header className={`app-header ${mode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={mode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <Menu mode="horizontal">
              {
                isNavTop && !isMobile
                  ? null
                  : (
                    <Menu.Item key="0" onClick={() => { onToggle(); }}>
                      {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                    </Menu.Item>
                  )
              }
              {/* { */}
              {/*  isMobile */}
              {/*    ? ( */}
              {/*      <Menu.Item key="1" onClick={() => { onSearchActive(); }}> */}
              {/*        <SearchOutlined /> */}
              {/*      </Menu.Item> */}
              {/*    ) */}
              {/*    : ( */}
              {/*      <Menu.Item key="1" style={{ cursor: 'auto' }}> */}
              {/*        <SearchInput mode={mode} isMobile={isMobile} /> */}
              {/*      </Menu.Item> */}
              {/*    ) */}
              {/* } */}
            </Menu>
          </div>
          <div className="nav-right">
            <NavHelp />
            <NavNotification />
            <NavProfile />
          </div>
          {/* <NavSearch
          active={searchActive} headerNavColor={headerNavColor} close={onSearchClose} /> */}
        </div>
      </div>
    </Header>
  );
};

HeaderNav.propTypes = {
  navCollapsed: PropTypes.bool.isRequired,
  mobileNav: PropTypes.bool.isRequired,
  navType: PropTypes.string.isRequired,
  headerNavColor: PropTypes.string.isRequired,
  toggleCollapsedNav: PropTypes.func.isRequired,
  onMobileNavToggle: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed, navType, headerNavColor, mobileNav,
  } = theme;
  return {
    navCollapsed, navType, headerNavColor, mobileNav,
  };
};

export default withRouter(connect(mapStateToProps,
  { toggleCollapsedNav, onMobileNavToggle })(HeaderNav));
