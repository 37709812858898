import { combineReducers } from 'redux';
import Theme from './Theme';
import Auth from './Auth';
import Constants from './Constants';
import LeadPreview from './LeadPreview';
import Loader from './Loader';
import UserPreview from './UserPreview';
import EditStatusDrawer from './EditStatusDrawer';
import SelectLead from './SelectLead';
import SelectMob from './SelectMob';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  constants: Constants,
  leadPreview: LeadPreview,
  loader: Loader,
  userPreview: UserPreview,
  editStatusDrawer: EditStatusDrawer,
  selectLead: SelectLead,
  selectMob: SelectMob
});

export default reducers;
