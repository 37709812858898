/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'antd';
import PropTypes from 'prop-types';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from '../../constants/ThemeConstant';
import Utils from '../../utils';
import { APP_NAME } from '../../constants/common';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return 'auto';
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH - 10}px`;
  }
  return `${SIDE_NAV_WIDTH - 10}px`;
};

const styleLogo = (props) => {
  const { navCollapsed } = props;
  if (navCollapsed) {
    return { width: '3rem' };
  }
  return { width: '3.5rem' };
};

const getLogo = (props) => {
  const { navCollapsed, logoType } = props;
  if (logoType === 'light') {
    if (navCollapsed) {
      return '/img/logo-icon.png';
    }
    return '/img/logo-dark.png';
  }

  if (navCollapsed) {
    return '/img/logo-icon.png';
  }
  return '/img/logo-dark.png';
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none';
  }
  return 'logo';
};

export const Logo = (props) => {
  const isMobile = !Utils.getBreakPoint(useBreakpoint()).includes('lg');
  return (
    <a href="/">
      <div
        className={getLogoDisplay(isMobile, props.mobileLogo)}
        style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
      >
        <img src={getLogo(props)} alt={`${APP_NAME} logo`} style={styleLogo(props)} />
      </div>
    </a>
  );
};

Logo.defaultProps = {
  mobileLogo: false,
};

Logo.propTypes = {
  mobileLogo: PropTypes.bool,
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
