import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ type, className }) => (
  React.createElement(type, { className })
);

Icon.propTypes = {
  type: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
