import {
  LOGIN,
  LOGOUT,
} from '../action-types';

export const loginAction = (payload) => ({
  type: LOGIN,
  payload,
});

export const logoutAction = (payload) => ({
  type: LOGOUT,
  payload,
});
