import { AUTH_PREFIX_PATH, ERROR_PREFIX_PATH, HOME_PREFIX_PATH } from '../constants/common';

const RedirectUser = {
  login: (response) => {
    const { user } = response;
    let path;
    if (window.location.pathname.indexOf(AUTH_PREFIX_PATH) >= 0
      || window.location.pathname.indexOf(ERROR_PREFIX_PATH) >= 0) {
      if (user.type === 'borrower') {
        path = HOME_PREFIX_PATH;
      } else {
        path = HOME_PREFIX_PATH;
      }
    } else {
      path = window.location.pathname + window.location.search;
    }
    return path;
  },

  logout: () => {
    let path;
    if (window.location.pathname.indexOf(AUTH_PREFIX_PATH) < 0) {
      path = AUTH_PREFIX_PATH;
    } else {
      path = window.location.pathname + window.location.search;
    }
    return path;
  },
};

export default RedirectUser;
