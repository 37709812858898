import React, { lazy, Suspense } from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../../../components/shared-components/Loading';

import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../../constants/ThemeConstant';
import BorrowerHeaderNav from '../../../components/layout-components/BorrowerHeaderNav';

const { Content } = Layout;

const Auth = (props) => {
  const isMobile = true;
  const isNavTop = props.navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (props.isAppLayoutHidden) {
      return 0;
    }
    if (isNavTop || isMobile) {
      return 0;
    }
    return props.navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const headerComponent = props.isAppLayoutHidden ? null
    : <BorrowerHeaderNav isMobile={isMobile} />;


  console.log("URL -------->  ",props.match.url)

  return (
    <Layout style={{
      backgroundColor: 'white', height: '100%', maxWidth: '500px', margin: 'auto',
    }}
    >
      {headerComponent}
      <Layout className="app-container">
        <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
          <div className={`app-content overflow-scroll ${props.isAppLayoutHidden ? 'm-0' : ''}`}>
            <div className="container pt-3 mb-3">
              <Content>
                <Suspense fallback={<Loading cover="page" />}>
                  <Switch>
                    <Route path={`${props.match.url}/get-started`} component={lazy(() => import('./get-started'))} />
                    <Route path={`${props.match.url}/apply`} component={lazy(() => import('./apply'))} />
                    <Route path={`${props.match.url}/login`} component={lazy(() => import('./login'))} />
                    <Redirect to={`${props.match.url}/get-started`} />
                  </Switch>
                </Suspense>
              </Content>
            </div>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

Auth.propTypes = {
  navCollapsed: PropTypes.bool.isRequired,
  isAppLayoutHidden: PropTypes.bool.isRequired,
  navType: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed, navType, locale, isAppLayoutHidden,
  } = theme;
  return {
    navCollapsed, navType, locale, isAppLayoutHidden,
  };
};

export default withRouter(connect(mapStateToProps, null)(React.memo(Auth)));
