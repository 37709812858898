import React, { lazy, Suspense } from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { Grid, Layout } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import LeadPreview from './common/lead-preview';
// import UserPreview from './common/user-preview';
// import EditStatusDrawer from './common/edit-status-drawer';
import Utils from '../../utils';
import navigationConfig from '../../configs/NavigationConfig';

import {
  NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH,
} from '../../constants/ThemeConstant';
import MobileNav from '../../components/layout-components/MobileNav';
import HeaderNav from '../../components/layout-components/HeaderNav';
import { hideAppLayout, showAppLayout } from '../../redux/actions';
import TopNav from '../../components/layout-components/TopNav';
import Loading from '../../components/shared-components/Loading';
import ErrorAccessDenied from '../errors-views/error-403';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const WebViews = (props) => {
  const currentRouteInfo = Utils.getRouteInfo(navigationConfig, props.location.pathname);
  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = props.navType === NAV_TYPE_SIDE;
  const isNavTop = props.navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (props.isAppLayoutHidden) {
      return 0;
    }
    if (isNavTop || isMobile) {
      return 0;
    }
    return props.navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  props.showAppLayout();

  const headerComponent = props.isAppLayoutHidden ? null : <HeaderNav isMobile={isMobile} />;
  let sideNavComponent = null;
  if (!props.isAppLayoutHidden) {
    sideNavComponent = (!isNavSide && !isMobile) ? <TopNav routeInfo={currentRouteInfo} /> : null;
  }

  if (props.auth.user && props.auth.user.type === 'borrower') {
    return <ErrorAccessDenied />;
  }

  return (
    <Layout>
      {headerComponent}
      <Layout className="app-container">
        {sideNavComponent}
        <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''} ${props.isAppLayoutHidden ? 'm-0' : ''}`}>
            <Content>
              <div>
                <Switch>
                  <Suspense fallback={<Loading cover="content" />}>
                    <Switch>
                      <Route path={`${props.match.url}/fi-tasks`} component={lazy(() => import('./fi_tasks'))} />
                      <Route path={`${props.match.url}/users`} component={lazy(() => import('./users'))} />
                      <Route path={`${props.match.url}/branches`} component={lazy(() => import('./branches'))} />
                      <Route path={`${props.match.url}/valuations`} component={lazy(() => import('./valuations'))} />
                      <Route path={`${props.match.url}/leads`} component={lazy(() => import('./leads'))} />
                      <Route path={`${props.match.url}/reports`} component={lazy(() => import('./reports'))} />
                      <Route path={`${props.match.url}/fi-internal`} component={lazy(() => import('./fi_internal'))} />
                      <Route path={`${props.match.url}/dsa`} component={lazy(() => import('./dsa'))} />
                      <Route path={`${props.match.url}/dashboard`} component={lazy(() => import('./dashboard'))} />
                      <Route path={`${props.match.url}/lead-approval`} component={lazy(() => import('./abcd'))} />
                      <Redirect to={`${props.match.url}/dashboard`} />
                    </Switch>
                  </Suspense>
                </Switch>
                {/* <LeadPreview /> */}
                {/* <UserPreview /> */}
                {/* <EditStatusDrawer /> */}
              </div>
            </Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && <MobileNav routeInfo={currentRouteInfo} />}
    </Layout>
  );
};

WebViews.propTypes = {
  navCollapsed: PropTypes.bool.isRequired,
  isAppLayoutHidden: PropTypes.bool.isRequired,
  navType: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  showAppLayout: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ theme, auth }) => {
  const {
    navCollapsed, navType, locale, isAppLayoutHidden,
  } = theme;
  return {
    navCollapsed, navType, locale, isAppLayoutHidden, auth,
  };
};

export default withRouter(connect(mapStateToProps,
  { hideAppLayout, showAppLayout })(React.memo(WebViews)));
