import _ from 'lodash';

const CommonUtils = {
  getQueryParam: (name) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(name);
  },

  getQueryParamCount: (filterArr) => {
    const queryArr = [];
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.forEach((value, key) => queryArr.push(key));

    let paramCount = queryArr.length;

    paramCount -= _.intersection(['page', 'page_size', 'search'], queryArr).length; // subtract count of non filter elements

    filterArr.forEach((arr) => {
      if (_.difference(arr, queryArr).length === 0) {
        paramCount -= 1;
      }
    });

    return paramCount;
  },

  getMyLocation(success, error) {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(success, error);
    }
    return null;
  },

  filterFlattenConstant: (flattenConstants, value) => {
    const foundConstants = flattenConstants && flattenConstants.filter((e) => e.value === value);
    if (foundConstants && foundConstants.length > 0) {
      return foundConstants[0].name;
    }
    return value;
  },

  formatLabels: (str) => {
    const splitStr = str.toLowerCase().split('_');
    splitStr.forEach((string, i) => {
      splitStr[i] = string.charAt(0).toUpperCase() + string.substring(1);
    });
    return splitStr.join(' ');
  },

  formatConstant: (value) => value.split('_').map((s) => (s[0].toUpperCase() + s.slice(1).toLowerCase())).join(' '),

  capitalize: (word) => word[0].toUpperCase() + word.slice(1).toLowerCase(),

  formatAmount: (amount, decimal = 2) => (amount ? `Rs. ${amount.toFixed(decimal)}` : 0),
  
  //formatAmount: (amount) => (amount ? `Rs. ${Math.round(amount)}.00` : 0),

  getFileList: (files = [], fileName) => {
    const res = [];
    if (!files) {
      return res;
    }
    files.forEach((f) => {
      if (f) {
        const obj = {
          uid: f.doc_id,
          name: f.name || fileName,
          url: f.file,
        };
        if (f.name) {
          obj.status = 'removed';
        } else {
          obj.status = 'done';
        }
        res.push(obj);
      }
    });
    return res;
  },

  getItemURL: (files = [], uid) => {
    let url;
    files.forEach((f) => {
      if (f.doc_id !== uid) {
        url = f.update_url;
      }
    });
    return url;
  },

  normFile: (e) => _.map(e.fileList, 'originFileObj'),

  makeAcronym: (str) => {
    if (!str) return '';
    return str.split('_').map((s) => (s[0].toUpperCase())).join('');
  },

  getCustomOptions: (objects, filterKey) => {
    if (objects && filterKey && objects[filterKey]) {
      return objects[filterKey]?.sort((a, b) => (a[1] > b[1] ? 1 : -1)).map((key) => (
        { name: key[1], value: key[0] }
      ));
    }
    return [];
  },

  parsePayoutSlab: (obj) => {
    const res = [];
    // eslint-disable-next-line no-restricted-syntax,no-unused-vars
    for (const [key, value] of Object.entries(obj)) {
      res.push({
        min: key.split('-')[0],
        max: key.split('-')[1],
        value,
      });
    }
    return res;
  },

  download: () => {
    const element = document.createElement('a');
    const file = new Blob(
      [],
      { type: 'image/*' },
    );
    element.href = URL.createObjectURL(file);
    element.download = 'image.jpg';
    element.click();
  },

  checkPermission: (user, permission) => {
    if (!(user && user.permissions)) {
      return false;
    }
    return user.permissions.indexOf(permission) >= 0;
  },

};

export default CommonUtils;
