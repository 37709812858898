import AxiosRequest from '../configs/AxiosRequest';

const ConstantService = {};

ConstantService.getCoreConstant = (flatten = null) => AxiosRequest.get(
  '/api/v1/core/constants/',
  { params: { flatten } },
);

ConstantService.getUserConstant = (flatten = null) => AxiosRequest.get(
  '/api/v1/user/constants/',
  { params: { flatten } },
);

export default ConstantService;
