import { SELECT_MOB } from "redux/action-types/SelectMob";


const initState = {
    mob: null,
  };
  
  const SelectMob = (state = initState, action) => {
    const { payload } = action;
    switch (action.type) {
      case SELECT_MOB:
        return {
          ...state,
          mob: payload.mob,
        };
  
      default:
        return state;
    }
  };
  
  export default SelectMob;