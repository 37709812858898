import React, { lazy, Suspense } from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { Grid, Layout } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import LeadPreview from './common/lead-preview';
// import UserPreview from './common/user-preview';
// import EditStatusDrawer from './common/edit-status-drawer';
import Utils from '../../utils';
import navigationConfig from '../../configs/NavigationConfig';

import {
  NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH,
} from '../../constants/ThemeConstant';
import MobileNav from '../../components/layout-components/MobileNav';
import HeaderNav from '../../components/layout-components/HeaderNav';
import { hideAppLayout, showAppLayout } from '../../redux/actions';
import TopNav from '../../components/layout-components/TopNav';
import Loading from '../../components/shared-components/Loading';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const AuthWebViews = (props) => {
  const currentRouteInfo = Utils.getRouteInfo(navigationConfig, props.location.pathname);
  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = props.navType === NAV_TYPE_SIDE;
  const isNavTop = props.navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (props.isAppLayoutHidden) {
      return 0;
    }
    if (isNavTop || isMobile) {
      return 0;
    }
    return props.navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  props.hideAppLayout();

  const headerComponent = props.isAppLayoutHidden ? null : <HeaderNav isMobile={isMobile} />;
  let sideNavComponent = null;
  if (!props.isAppLayoutHidden) {
    sideNavComponent = (!isNavSide && !isMobile) ? <TopNav routeInfo={currentRouteInfo} /> : null;
  }

  return (
    <Layout>
      {headerComponent}
      <Layout className="app-container">
        {sideNavComponent}
        <Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''} ${props.isAppLayoutHidden ? 'm-0' : ''}`}>
            <Content>
              <div>
                <Switch>
                  <Suspense fallback={<Loading cover="content" />}>
                    <Switch>
                      <Route path={`${props.match.url}/login`} component={lazy(() => import('./login-user'))} />
                      <Redirect to={`${props.match.url}/login`} />
                    </Switch>
                  </Suspense>
                </Switch>
                {/* <LeadPreview /> */}
                {/* <UserPreview /> */}
                {/* <EditStatusDrawer /> */}
              </div>
            </Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && <MobileNav routeInfo={currentRouteInfo} />}
    </Layout>
  );
};

AuthWebViews.propTypes = {
  navCollapsed: PropTypes.bool.isRequired,
  isAppLayoutHidden: PropTypes.bool.isRequired,
  navType: PropTypes.string.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  hideAppLayout: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed, navType, locale, isAppLayoutHidden,
  } = theme;
  return {
    navCollapsed, navType, locale, isAppLayoutHidden,
  };
};

export default withRouter(connect(mapStateToProps,
  { hideAppLayout, showAppLayout })(React.memo(AuthWebViews)));
