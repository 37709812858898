import axios from 'axios';
import { message, notification } from 'antd';
import {
  API_BASE_URL,
  SESSION_HEADER_KEY, SESSION_ID_KEY, HOME_PREFIX_PATH,
} from '../constants/common';
import store from '../redux/store/index';
import { START_LOADING, STOP_LOADING } from '../redux/action-types';

const service = axios.create({
  baseURL: API_BASE_URL,
});

let messageHide = null;

// API Request interceptor
service.interceptors.request.use((config) => {
  const jwtToken = localStorage.getItem(SESSION_ID_KEY);
  // jwtToken is the session id which is coming from login api response ---------------------------------------------------- ||

  if (['patch', 'put', 'delete', 'post'].indexOf(config.method) >= 0 && !config.extraParams?.isSilentCall) {
    store.dispatch({ type: START_LOADING });
    messageHide = message.loading('Loading...');
  }

  if (jwtToken) {
    // console.log(jwtToken);
    // eslint-disable-next-line no-param-reassign
    config.headers[SESSION_HEADER_KEY] = jwtToken;
  }

  if (window.location.pathname.indexOf(HOME_PREFIX_PATH) >= 0) {
    // eslint-disable-next-line no-param-reassign
    config.headers['x-app-name'] = 'in-house-web';
  } else {
    // eslint-disable-next-line no-param-reassign
    config.headers['x-app-name'] = 'borrower-web';
  }

  return config;
}, (error) => {
  // Do something with Request error here
  notification.error({
    message: 'Error',
  });
  Promise.reject(error);
});

// API response interceptor
service.interceptors.response.use((response) => {
  if (messageHide) {
    messageHide();
  }

  if (!response.config.extraParams?.isSilentCall) {
    if (['patch', 'put'].indexOf(response.config.method) >= 0 && !response.config.extraParams?.isPutMsgNotAllowed) {
      message.success(response.config.extraParams?.responseMsg || response.data.message || 'Updated successfully', 2);
    }
    if (['delete'].indexOf(response.config.method) >= 0 && !response.config.extraParams?.isDeleteMsgNotAllowed) {
      message.error(response.config.extraParams?.responseMsg || response.data.message || 'Deleted successfully', 2);
    }
    if (['post'].indexOf(response.config.method) >= 0 && !response.config.extraParams?.isPostMsgNotAllowed) {
      message.success(response.config.extraParams?.responseMsg || response.data.message || 'Created successfully', 2);
    }
    store.dispatch({ type: STOP_LOADING });
  }

  return response.data;
}, (error) => {
  console.log(error)
  if (messageHide) {
    messageHide();
  }

  const notificationParam = {
    message: 'Error occurred',
  };

  // Remove token and redirect
  if (error.response.status === 401 || error.response.status === 403) {
    console.log(error.response);
    notificationParam.message = 'Authentication Failed';
    notificationParam.description = 'Please login again';
    localStorage.removeItem(SESSION_ID_KEY);
    window.location = '/';
  }
  if (!error.response.config.extraParams?.is403MsgNotAllowed && error.response.status === 403) {
    notificationParam.description = 'Permission denied';
  }

  if (!error.response.config.extraParams?.is404MsgNotAllowed && error.response.status === 404) {
    notificationParam.description = 'Unable to find';
  }

  if (error.response.status === 500) {
    notificationParam.description = 'Internal Server Error';
  }

  if (error.response.status === 504) {
    notificationParam.description = 'Request Timed Out';
  }
  if (!error.response.config.extraParams?.is400MsgNotAllowed && error.response.status === 400) {
    if (error.response.data.message) {
      notificationParam.description = error.response.data.message;
    } else {
      notificationParam.description = `${Object.keys(error.response.data)[0]}:
       ${error.response.data[Object.keys(error.response.data)[0]]}`;
    }
  }

  if (notificationParam.description && !error.response.data.code) {
    notification.error(notificationParam);
  }

  store.dispatch({ type: STOP_LOADING });
  return Promise.reject(error.response.data);
});

export default service;
