/* eslint-disable */
/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Menu } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../util-components/Icon';
import IntlMessage from '../util-components/IntlMessage';
import Utils from '../../utils';
import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from '../../constants/ThemeConstant';
import navigationConfig from '../../configs/NavigationConfig';
import { onMobileNavToggle } from '../../redux/actions';
import CommonUtils from '../../utils/commonUtil';

const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) => (
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString()
);

const setDefaultOpen = (key) => {
  const keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index += 1) {
      const elm = arr[index];
      if (index === 0) {
        (keyString = elm);
      } else {
        (keyString = `${keyString}-${elm}`);
      }
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme, routeInfo, hideGroupTitle, localization,
  } = props;
  const isMobile = !Utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      props.onMobileNavToggle(false);
    }
  };
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      selectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {navigationConfig.map((menu) => (menu.submenu?.length > 0 ? (
        <Menu.SubMenu
          key={menu.key}
          popupClassName="top-nav-menu"
          title={(
            <span>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu.title)}</span>
            </span>
          )}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {menu.submenu.map((submenu) => (
            CommonUtils.checkPermission(props.user, submenu.permission)
              ? (
                <Menu.Item key={submenu.key}>
                  {submenu.icon ? (
                    <Icon type={submenu?.icon} />
                  ) : null}
                  <span>{setLocale(localization, submenu.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={submenu.path} />
                </Menu.Item>
              ) : <div />
          ))}
        </Menu.SubMenu>
      ) : (
        CommonUtils.checkPermission(props.user, menu.permission)
          ? (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
            </Menu.Item>
          )
          : <div />
      )))}
    </Menu>
  );
};

const TopNavContent = (props) => {
  // eslint-disable-next-line react/prop-types
  const { topNavColor } = props;
  // const menuStyles = {
  //   // height: '100%', opacity: 1, position: 'initial', pointerEvents: 'initial',
  // };
  return (
    <Menu
      mode="horizontal"
      style={{ backgroundColor: topNavColor }}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {navigationConfig.map((menu) => (menu.submenu?.length > 0 ? (
        <Menu.SubMenu
          key={menu.key}
          popupClassName="top-nav-menu"
          title={(
            <span>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{menu?.title}</span>
            </span>
            )}
        >
          {menu.submenu.map((subMenuFirst) => (
            // eslint-disable-next-line react/prop-types
            CommonUtils.checkPermission(props.user, menu.permission)
              ? (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{subMenuFirst?.title}</span>
                  {subMenuFirst.path ? <Link to={subMenuFirst.path} /> : null}
                </Menu.Item>
              )
              : <Menu.Item />
          ))}
        </Menu.SubMenu>
      ) : (
        // eslint-disable-next-line react/prop-types
        CommonUtils.checkPermission(props.user, menu.permission)
          ? (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{menu?.title}</span>
              {menu.path ? <Link to={menu.path} /> : null}
            </Menu.Item>
          )
          : <Menu.Item />
      )))}
    </Menu>
  );
};

const MenuContent = (props) => {
  // eslint-disable-next-line react/prop-types
  if (props.type === NAV_TYPE_SIDE) {
    return <SideNavContent {...props} />;
  }
  return <TopNavContent {...props} />;
};

SideNavContent.defaultProps = {
  routeInfo: {},
  hideGroupTitle: '',
};

SideNavContent.propTypes = {
  sideNavTheme: PropTypes.string.isRequired,
  routeInfo: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  hideGroupTitle: PropTypes.string,
  localization: PropTypes.bool.isRequired,
  onMobileNavToggle: PropTypes.func.isRequired,
};

TopNavContent.propTypes = {
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default withRouter(connect(mapStateToProps, { onMobileNavToggle })(MenuContent));
