import React from 'react';
import { Button, Row, Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Flex from '../../../components/shared-components/Flex';
import { APP_NAME } from '../../../constants/common';
import { logoutAction } from '../../../redux/actions';
import RedirectUser from '../../../utils/redirectUser';

const ErrorAccessDenied = (props) => {
  const onLogout = () => {
    props.logoutAction();
    props.history.push(RedirectUser.logout());
  };
  return (
    <div className="h-100 bg-white">
      <div className="container-fluid d-flex flex-column justify-content-between h-100 px-md-4 pb-md-4 pt-md-1">
        <div>
          <img className="img-fluid" src="/img/logo.png" alt="" />
        </div>
        <div className="container">
          <Row align="middle">
            <Col xs={24} sm={24} md={8}>
              <h1 className="font-weight-bold mb-4 display-4">Access denied</h1>
              <p className="font-size-md mb-4">We&apos;ve noticed that you are trying to access restricted page.</p>
              <Button type="primary" icon={<ArrowLeftOutlined />} onClick={() => onLogout()}>Go back</Button>
            </Col>
            <Col xs={24} sm={24} md={{ span: 14, offset: 2 }}>
              <img className="img-fluid mt-md-0 mt-4" src="/img/others/img-20.svg" alt="" />
            </Col>
          </Row>
        </div>
        <Flex mobileFlex={false} justifyContent="between">
          <span>
            Copyright  &copy;
            {`${new Date().getFullYear()}`}
            {' '}
            <span className="font-weight-semibold">{`${APP_NAME}`}</span>
          </span>
        </Flex>
      </div>

    </div>
  );
};

ErrorAccessDenied.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(connect(null, { logoutAction })(ErrorAccessDenied));
