import { NAV_TYPE_TOP, SIDE_NAV_DARK } from '../constants/ThemeConstant';

export default {
  navCollapsed: false,
  isAppLayoutHidden: false,
  sideNavTheme: SIDE_NAV_DARK,
  locale: 'en',
  navType: NAV_TYPE_TOP,
  topNavColor: '#e3e5e8',
  headerNavColor: '#ffffff',
  mobileNav: false,
  localization: true,

  primary: '#41853c',
  danger: '#ff6b72',
  success: '#04d182',
  warning: '#ffc542',
  secondary: '#455560',
  info: '#3e82f7',
};
