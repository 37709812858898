const dev = {
  // API_ENDPOINT_URL: 'https://abhishek2.loca.lt',
  // API_ENDPOINT_URL: 'http://localhost:8000',
  // API_ENDPOINT_URL: 'http://192.168.1.17:8000',
    // API_ENDPOINT_URL: 'http://127.0.0.1:8000',
  // API_ENDPOINT_URL: 'http://182.73.216.92:8000',
  // API_ENDPOINT_URL: 'http://3.6.57.75:8000',
  API_ENDPOINT_URL: 'https://api.raftar.loan',
  // API_ENDPOINT_URL: 'https://api.stag.paisagrowth.in',
  REACT_APP_VAPID_KEY: 'BIvCdh1PH5-RtIu2CCEbVJ-JeKt_VZ_kGzDGl-DjFGeQw7WzT4aul3S7wO0ITuEs5swDFGiEgQtvqcDMmVFq5M8',
};

const stag = {
  // API_ENDPOINT_URL: 'https://abhishek2.loca.lt',
   API_ENDPOINT_URL: 'https://api.raftar.loan',
    // API_ENDPOINT_URL: 'http://127.0.0.1:8000',
  REACT_APP_VAPID_KEY: 'BIvCdh1PH5-RtIu2CCEbVJ-JeKt_VZ_kGzDGl-DjFGeQw7WzT4aul3S7wO0ITuEs5swDFGiEgQtvqcDMmVFq5M8',
};

const prod = {
   API_ENDPOINT_URL: 'https://api.raftar.loan',
  // API_ENDPOINT_URL: 'http://192.168.1.17:8000',
  // API_ENDPOINT_URL: 'http://182.73.216.92:8000',
     //API_ENDPOINT_URL: 'http://127.0.0.1:8000',
  // API_ENDPOINT_URL: 'https://api.raftar.loan',
  REACT_APP_VAPID_KEY: 'BIvCdh1PH5-RtIu2CCEbVJ-JeKt_VZ_kGzDGl-DjFGeQw7WzT4aul3S7wO0ITuEs5swDFGiEgQtvqcDMmVFq5M8',
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return dev;
    case 'prod':
      return prod;
    case 'stag':
      return stag;
    default:
      return dev;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const env = getEnv();
