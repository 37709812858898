/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from '../../constants/ThemeConstant';
import { onMobileNavToggle, toggleCollapsedNav } from '../../redux/actions';
import Utils from '../../utils';
import NavHelp from './NavHelp';

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed, navType, headerNavColor, isMobile,
  } = props;

  const isNavTop = navType === NAV_TYPE_TOP;
  const mode = Utils.getColorContrast(headerNavColor);
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    }
    return `${SIDE_NAV_WIDTH}px`;
  };
  return (
    <Header className={`app-header ${mode}`} style={{ backgroundColor: headerNavColor, maxWidth: '500px', margin: 'auto' }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={mode} mobileLogo />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-right">
            {
              props.isHelp
                ? <NavHelp />
                : <div />
            }
            {
              props.isNotification
                ? <NavNotification />
                : <div />
            }
            {
              props.isLoggedIn
                ? <NavProfile />
                : <div />
            }
            {/* { */}
            {/*  !props.isLoggedIn */}
            {/*    ? ( */}
            {/*      <Link */}
            {/*        to={`${AUTH_PREFIX_PATH}/login`} */}
            {/*        style={{ margin: 'auto' }} */}
            {/*      > */}
            {/*        Login */}
            {/*        {' '} */}
            {/*        <LoginOutlined /> */}
            {/*      </Link> */}
            {/*    ) */}
            {/*    : <div /> */}
            {/* } */}
          </div>
        </div>
      </div>
    </Header>
  );
};

HeaderNav.propTypes = {
  navCollapsed: PropTypes.bool.isRequired,
  navType: PropTypes.string.isRequired,
  headerNavColor: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isNotification: PropTypes.bool,
  isHelp: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
};

HeaderNav.defaultProps = {
  isNotification: false,
  isHelp: false,
};

const mapStateToProps = ({ theme, auth }) => {
  const {
    navCollapsed, navType, headerNavColor, mobileNav,
  } = theme;
  return {
    navCollapsed, navType, headerNavColor, mobileNav, isLoggedIn: auth.isLoggedIn,
  };
};

export default withRouter(connect(mapStateToProps,
  { toggleCollapsedNav, onMobileNavToggle })(HeaderNav));
