/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import {
  Divider, Dropdown, Menu, Popover,
} from 'antd';
import { connect } from 'react-redux';
import {
  ContactsOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { logoutAction } from '../../redux/actions';
import Icon from '../util-components/Icon';
import { SUPPORT_EMAIL, SUPPORT_NUMBER, REPORT_ISSUE_LINK } from '../../constants/common';

const menuItem = [
  {
    title: 'Report an Issue',
    icon: ExclamationCircleOutlined,
    path: REPORT_ISSUE_LINK,
    key: 'report_issue',
  },
];

const NavHelp = () => {
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            <h4 className="mb-0">Help & Support</h4>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu mode="inline" selectable={false}>
          {menuItem.map((el) => (
            <Menu.Item key={el.key}>
              <a href={el.path} target="_blank" rel="noopener noreferrer">
                <Icon className="mr-3" type={el.icon} />
                <span className="font-weight-normal">{el.title}</span>
              </a>
            </Menu.Item>
          ))}
          <Menu.Item key={menuItem.length + 1}>
            <Popover
              placement="leftBottom"
              className="font-weight-normal"
              content={(
                <div>
                  <a href={`tel:${SUPPORT_NUMBER}`}>
                    <Icon className="mr-3" type={PhoneOutlined} />
                    <span className="font-weight-normal">{`Call at ${SUPPORT_NUMBER}`}</span>
                  </a>
                  <a href={`mailto:${SUPPORT_EMAIL}`}>
                    <Divider className="m-2" />
                    <Icon className="mr-3" type={MailOutlined} />
                    <span className="font-weight-normal">{`${SUPPORT_EMAIL}`}</span>
                  </a>
                </div>
              )}
              title="Contact Support"
            >
              <Icon className="mr-4" type={ContactsOutlined} />
              Contact Support
            </Popover>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <QuestionOutlined className="nav-icon mx-auto" type="bell" />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth }) => ({ user: auth.user });

export default withRouter(connect(mapStateToProps, { logoutAction })(NavHelp));
