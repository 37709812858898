import { CLOSE_EDIT_WINDOW, OPEN_EDIT_WINDOW } from '../action-types';

export const openEditWindow = (payload) => ({
  type: OPEN_EDIT_WINDOW,
  payload,
});

export const closeEditWindow = (payload) => ({
  type: CLOSE_EDIT_WINDOW,
  payload,
});
