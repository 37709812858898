import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

const Loading = (props) => {
  const { align, cover, size } = props;
  return (
    <div className={`loading p-4 text-${align} cover-${cover}`}>
      <Spin indicator={Icon} size={size} />
    </div>
  );
};

Loading.propTypes = {
  size: PropTypes.string,
  cover: PropTypes.string,
  align: PropTypes.string,
};

Loading.defaultProps = {
  align: 'center',
  cover: 'inline',
  size: '',
};

export default Loading;
