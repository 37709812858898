/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { Drawer } from 'antd';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import { NAV_TYPE_SIDE } from '../../constants/ThemeConstant';
import { onMobileNavToggle } from '../../redux/actions';
import Flex from '../shared-components/Flex';
import Logo from './Logo';
import MenuContent from './MenuContent';

export const MobileNav = (props) => {
  const onClose = () => {
    props.onMobileNavToggle(false);
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={props.mobileNav}
      bodyStyle={{ padding: 0 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo />
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent type={NAV_TYPE_SIDE} {...props} />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
};

MobileNav.propTypes = {
  onMobileNavToggle: PropTypes.func.isRequired,
  mobileNav: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed, sideNavTheme, mobileNav, localization,
  } = theme;
  return {
    navCollapsed, sideNavTheme, mobileNav, localization,
  };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MobileNav);
