import React, { Component } from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import AppLocale from '../lang';
import { AUTH_PREFIX_PATH, ERROR_PREFIX_PATH, HOME_PREFIX_PATH } from '../constants/common';
import ConstantService from '../services/ConstantService';
import {
  coreConstants,
  coreFlattenConstants,
  hideAppLayout,
  loginAction, logoutAction, setLocation,
  userConstants,
  userFlattenConstants,
} from '../redux/actions';
import Loading from '../components/shared-components/Loading';
import ErrorLayout from './errors-views';
import BorrowerViews from './borrower-views/pages';
import AuthBorrowerViews from './borrower-views/auth';
import AuthService from '../services/AuthService';
import WebViews from './web-views/web-views';
import AuthWebViews from './web-views/auth-web-views';
import RedirectUser from '../utils/redirectUser';
import CommonUtils from '../utils/commonUtil';

class Views extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    CommonUtils.getMyLocation((res) => {
      this.props.setLocation({
        latitude: res.coords.latitude,
        longitude: res.coords.longitude,
      });
    }, () => {
      // this.props.history.push(`${ERROR_PREFIX_PATH}/location`);
    });
    Promise.all([
      ConstantService.getCoreConstant(),
      ConstantService.getCoreConstant(true),
      ConstantService.getUserConstant(),
      ConstantService.getUserConstant(true),
    ]).then((results) => {
      this.props.coreConstants(results[0]);
      this.props.coreFlattenConstants(results[1]);
      this.props.userConstants(results[2]);
      this.props.userFlattenConstants(results[3]);

      this.setState({ isLoading: false });

      if (!this.props.sessionId) {
        this.setState({ isLoading: false });
        this.props.logoutAction();
        this.props.history.push(RedirectUser.logout());
        return;
      }

      if (this.props.sessionId) {
        AuthService.authPing().then((res) => {
          this.props.loginAction({
            sessionId: res.session_id,
            user: res.user,
          });
          this.props.history.push(RedirectUser.login(res));
          this.setState({ isLoading: false });
        }).catch(() => {
          this.setState({ isLoading: false });
          this.props.logoutAction();
          this.props.history.push(RedirectUser.logout());
        });
      }
    });
  }

  render() {
    const currentAppLocale = AppLocale[this.props.locale];

    if (this.state.isLoading || this.props.isLoggedIn === null) {
      return <Loading />;
    }

    if (process.env.REACT_APP_APP_NAME === 'borrower') {
      return (
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ConfigProvider locale={currentAppLocale.antd}>
            <Switch>
              <Route path={ERROR_PREFIX_PATH}>
                <ErrorLayout />
              </Route>
              <Route path={HOME_PREFIX_PATH}>
                <BorrowerViews location={window.location} />
              </Route>
              <Route path={AUTH_PREFIX_PATH}>
                <AuthBorrowerViews location={window.location} />
              </Route>
              <Redirect to={ERROR_PREFIX_PATH} />
            </Switch>
          </ConfigProvider>
        </IntlProvider>
      );
    }
    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ConfigProvider locale={currentAppLocale.antd}>
          <Switch>
            <Route path={ERROR_PREFIX_PATH}>
              <ErrorLayout />
            </Route>
            <Route path={HOME_PREFIX_PATH}>
              <WebViews location={window.location} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthWebViews location={window.location} />
            </Route>
            <Redirect to={ERROR_PREFIX_PATH} />
          </Switch>
        </ConfigProvider>
      </IntlProvider>
    );
  }
}

Views.defaultProps = {
  sessionId: '',
};

Views.propTypes = {
  locale: PropTypes.string.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  loginAction: PropTypes.func.isRequired,
  coreConstants: PropTypes.func.isRequired,
  coreFlattenConstants: PropTypes.func.isRequired,
  userConstants: PropTypes.func.isRequired,
  userFlattenConstants: PropTypes.func.isRequired,
  sessionId: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  logoutAction: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, location } = theme;
  const { isLoggedIn, sessionId } = auth;
  return {
    locale, isLoggedIn, sessionId, location,
  };
};

export default withRouter(connect(mapStateToProps, {
  loginAction,
  coreConstants,
  setLocation,
  coreFlattenConstants,
  userConstants,
  userFlattenConstants,
  hideAppLayout,
  logoutAction,
})(Views));
