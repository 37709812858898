import { CLOSE_LEAD_PREVIEW, OPEN_LEAD_PREVIEW } from '../action-types';

const initState = {
  isVisible: false,
  leadId: null,
};

const LeadPreview = (state = initState, action) => {
  const { payload } = action;
  switch (action.type) {
    case OPEN_LEAD_PREVIEW:
      return {
        ...state,
        leadId: payload.leadId,
        isVisible: true,
      };

    case CLOSE_LEAD_PREVIEW:
      return {
        ...state,
        leadId: null,
        isVisible: false,
      };

    default:
      return state;
  }
};

export default LeadPreview;
