/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { connect } from 'react-redux';
import MenuContent from './MenuContent';
import utils from '../../utils';
import { NAV_TYPE_TOP } from '../../constants/ThemeConstant';

// eslint-disable-next-line react/prop-types
export const TopNav = ({ topNavColor, localization = true }) => {
  const props = { topNavColor, localization };
  return (
    <div className={`top-nav ${utils.getColorContrast(topNavColor)}`} style={{ backgroundColor: topNavColor }}>
      <div className="top-nav-wrapper">
        <MenuContent
          type={NAV_TYPE_TOP}
          {...props}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { topNavColor } = theme;
  return { topNavColor };
};

export default connect(mapStateToProps)(TopNav);
