import React, { lazy, Suspense } from 'react';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { ERROR_PREFIX_PATH } from '../../constants/common';
import Loading from '../../components/shared-components/Loading';

const ErrorLayout = () => (
  <div className="auth-container">
    <Switch>
      <Suspense fallback={<Loading cover="page" />}>
        <Switch>
          <Route path={`${ERROR_PREFIX_PATH}/404`} component={lazy(() => import('./error-404'))} />
          <Route path={`${ERROR_PREFIX_PATH}/500`} component={lazy(() => import('./error-500'))} />
          <Route path={`${ERROR_PREFIX_PATH}/location`} component={lazy(() => import('./location'))} />
          <Redirect to={`${ERROR_PREFIX_PATH}/404`} />
        </Switch>
      </Suspense>
    </Switch>
  </div>
);

export default withRouter(ErrorLayout);
