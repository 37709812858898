import { LOGIN, LOGOUT } from '../action-types';
import { SESSION_ID_KEY } from '../../constants/common';

const initState = {
  sessionId: localStorage.getItem(SESSION_ID_KEY),
  isLoggedIn: null,
  user: null,
  mobile: null
};

const Auth = (state = initState, action) => {
  const { payload } = action;

  switch (action.type) {
    case LOGIN:
      localStorage.setItem(SESSION_ID_KEY, payload.sessionId);
      return {
        ...state,
        sessionId: payload.sessionId,
        isLoggedIn: true,
        user: payload.user,
        mobile: payload.mobile
      };

    case LOGOUT:
      localStorage.removeItem(SESSION_ID_KEY);
      return {
        ...state,
        sessionId: null,
        isLoggedIn: false,
        user: null,
        mobile: null
      };

    default:
      return state;
  }
};

export default Auth;
