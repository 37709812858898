/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import store from './redux/store';
import Views from './views';

const App = () => (
  <div className="App" style={{ height: '100%' }}>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/">
            <Views />
          </Route>
        </Switch>
      </Router>
    </Provider>
  </div>
);

export default Sentry.withProfiler(App);
