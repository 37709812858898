import { SELECT_LEAD } from "redux/action-types/SelectLead";


const initState = {
    id: null,
    applicantId: null,
    leadId: null,
  };
  
  const SelectLead = (state = initState, action) => {
    const { payload } = action;
    switch (action.type) {
      case SELECT_LEAD:
        return {
          ...state,
          id: payload.id,
          applicantId: payload.applicantId,
          leadId: payload.leadId,
        };
  
      default:
        return state;
    }
  };
  
  export default SelectLead;