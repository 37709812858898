/*eslint linebreak-style: ["error", "windows"]*/
import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { connect } from 'react-redux';
import {
  LogoutOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { logoutAction } from '../../redux/actions';
import Icon from '../util-components/Icon';
import RedirectUser from '../../utils/redirectUser';

const menuItem = [
  // {
  //   title: 'Edit Profile',
  //   icon: EditOutlined,
  //   path: `${HOME_PREFIX_PATH}/account/profile`,
  //   key: 'edit',
  // },
];

export const NavProfile = (props) => {
  if (!props.user) {
    return <div />;
  }
  const onLogout = () => {
    props.logoutAction();
    props.history.push(RedirectUser.logout());
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={props.user.photo} />
          <div className="pl-3">
            <h5 className="mb-0">{props.user.full_name}</h5>
            <span className="text-muted">{props.user.primary_mobile?.detail}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el) => (
            <Menu.Item key={el.key}>
              <a href={el.path}>
                <Icon className="mr-3" type={el.icon} />
                <span className="font-weight-normal">{el.title}</span>
              </a>
            </Menu.Item>
          ))}
          <Menu.Item key={menuItem.length + 1} onClick={() => onLogout()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={props.user.applicant?.lead?.avatar} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

NavProfile.defaultProps = {
  user: null,
};

NavProfile.propTypes = {
  logoutAction: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = ({ auth }) => ({ user: auth.user });

export default withRouter(connect(mapStateToProps, { logoutAction })(NavProfile));
