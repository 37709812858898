import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';


// if (process.env.REACT_APP_ENV === 'prod') {
//   // Sentry.init({
//   //   dsn: 'https://bf262a05013149d384b2f13332743323@o912718.ingest.sentry.io/6250814',
//   //   integrations: [new BrowserTracing()],
//   //   ignoreErrors: [
//   //     'ResizeObserver loop limit exceeded',
//   //   ],
//   //
//   //   // Set tracesSampleRate to 1.0 to capture 100%
//   //   // of transactions for performance monitoring.
//   //   // We recommend adjusting this value in production
//   //   tracesSampleRate: 1.0,
//   // });
// }

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register-advisor() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
