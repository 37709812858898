import { CLOSE_USER_PREVIEW, OPEN_USER_PREVIEW } from '../action-types';

const initState = {
  isVisible: false,
  userId: null,
};

const UserPreview = (state = initState, action) => {
  const { payload } = action;
  switch (action.type) {
    case OPEN_USER_PREVIEW:
      return {
        ...state,
        userId: payload.userId,
        isVisible: true,
      };

    case CLOSE_USER_PREVIEW:
      return {
        ...state,
        userId: null,
        isVisible: false,
      };

    default:
      return state;
  }
};

export default UserPreview;
